<template>
  <div>
    <view-banners></view-banners>
    <section class="section-container-title container-fluid section text-white" style="padding-bottom:0px !important">
      <div class="row">
        <div class="col-md-8 col-lg-9 order-1 mb-5 mb-md-0 ">
          <view-noticias></view-noticias>
        </div>
        <div class="col-md-4 col-lg-3 order-2 bg-light-2">
          <view-tweets></view-tweets>
        </div>
      </div>
    </section>
    <section class="section-container-title container-fluid section text-white">
      <div class="row">
        <div class="col-md-12 ">
          <view-voluntariado></view-voluntariado>
        </div>
      </div>
    </section>
    <!-- <script type="application/javascript" src="assets/vendor/bootstrap/js/popper.js"></script>
    <script type="application/javascript" src="assets/js/other.js"></script>
    <script type="application/javascript" src="assets/js/page.js"></script> -->
  </div>
</template>

<script>
import viewBanners from "@/components/home/view-banners";
import viewNoticias from "@/components/home/view-noticias";
import viewTweets from "@/components/home/view-tweets";
import viewVoluntariado from "@/components/home/view-voluntariado";

export default {
  name: "view-home",
  metaInfo: {
    title: "Transparencia Perú",
  },

  components: {
    viewBanners,
    viewNoticias,
    viewTweets,
    viewVoluntariado
  },
  props: {},

  methods: {},
  mounted: function() {

  },
};
</script>
