<template>
  <div class="p-4">
    <div id="carouselExampleFade" class="carousel slide carousel-fade" data-ride="carousel">
      <div class="carousel-inner">
        <template v-for="(banner, index) in banners">
          <div :key="index" class="carousel-item" :class="index == 0 ? 'active' : ''" style="border-radius:7px;">
            <div class="d-none d-sm-block">
              <a v-if="banner.link != null && banner.link != ''" :href="banner.link" target="_blank">
                <img style="border-radius:7px;" class="d-block w-100" :src="banner.rutaImagen" :alt="banner.nombre" v-if="banner.esEnlaceExterno" />
                <img style="border-radius:7px;" class="d-block w-100" :src="`${filesUrl + banner.rutaImagen}`" :alt="banner.nombre" />
              </a>
              <a v-else>
                <img style="border-radius:7px;" class="d-block w-100" :src="banner.rutaImagen" :alt="banner.nombre" v-if="banner.esEnlaceExterno" />
                <img style="border-radius:7px;" class="d-block w-100" :src="`${filesUrl + banner.rutaImagen}`" :alt="banner.nombre" />
              </a>
            </div>
            <div class="d-block d-sm-none">
              <a v-if="banner.link != null && banner.link != ''" :href="banner.link" target="_blank">
                <img style="border-radius:7px;" class="d-block w-100" :src="banner.rutaImagenMobile" :alt="banner.nombre" v-if="banner.esEnlaceExterno" />
                <img style="border-radius:7px;" class="d-block w-100" :src="`${filesUrl + banner.rutaImagenMobile}`" :alt="banner.nombre" />
              </a>
              <a v-else>
                <img style="border-radius:7px;" class="d-block w-100" :src="banner.rutaImagenMobile" :alt="banner.nombre" v-if="banner.esEnlaceExterno" />
                <img style="border-radius:7px;" class="d-block w-100" :src="`${filesUrl + banner.rutaImagenMobile}`" :alt="banner.nombre" />
              </a>
            </div>
          </div>
        </template>
      </div>
      <button class="carousel-control-prev" type="button" data-target="#carouselExampleFade" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previo</span>
      </button>
      <button class="carousel-control-next" type="button" data-target="#carouselExampleFade" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Siguiente</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "view-banners",
  data: () => ({
    banners: [],
  }),
  methods: {
    fetchBanners: function() {
      this.$http.get(this.apiRoute("Common", "ListBannerAll")).then(function(response) {
        if (!response.body.error) {
          this.banners = response.body.data;
        }
      });
    },
  },
  mounted: function() {
    this.fetchBanners();
  },
};
</script>
