<template>
  <div class="pl-5 pr-5">
    <header class="section-header section-header-news">
      <!-- <h2 class="text-dark font-weight-bold"><mark data-aos="highlight-text" data-aos-delay="250" class="aos-init aos-animate"> Voluntariado </mark></h2> -->
      <h2 class="text-transparencia font-weight-700">VOLUNTARIADO</h2>
      <hr style="border-top-color: rgba(0, 0, 0, 0.15) !important;" />
    </header>
    <!-- <div class="card">
      <div class="card-body"> -->
    <div id="slider-voluntariado">
      <template v-for="(actividad, index) in actividades">
        <div class="p-2" :key="index">
          <div class="card card-frame card-owework border hover-shadow-9 transition-hover  text-center">
            <router-link :to="'voluntariado/detalle/' + actividad.voluntariadoActividadId">
              <img class="img-fluid w-100 rounded cimage-slider" :src="`${filesUrl + actividad.rutaImagenMiniatura}`" :alt="actividad.titulo" />
            </router-link>
            <div class="card-body p-2">
              <div class="d-flex justify-content-center text-center" style="height:5.5rem !important;overflow:hidden">
                <router-link :to="'voluntariado/detalle/' + actividad.voluntariadoActividadId">
                  <h6 class="card-title fs-15 text-transparencia text-center">{{ actividad.titulo }}</h6>
                </router-link>
              </div>
            </div>
            <div class="card-footer text-right pt-0">
              <router-link class="fs-13 fw-600  anim-link-2 anim-link-2-primary" :to="'voluntariado/detalle/' + actividad.voluntariadoActividadId">Leer más<i class="fa fa-search-plus ml-1"></i></router-link>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="col-md-12 text-center mt-2">
      <router-link to="/voluntariado/actividades" class="btn btn-primary btn-transparencia">Ver todo</router-link>
    </div>
    <!-- </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "view-voluntariado",
  components: {},
  data: () => ({
    actividades: [],
  }),
  methods: {
    fetchActivities: async function() {
      this.$http.get(this.apiRoute("Common", "ListVoluntariadoActividadesAll")).then(function(response) {
        if (!response.body.error) {
          this.actividades = response.body.data;
        }
        startSlider("slider-voluntariado", [6, 5, 4, 3, 2, 1]);
      });
    },
  },
  mounted: async function() {
    this.fetchActivities();
  },
};
</script>
