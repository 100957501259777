<template>
  <div>
    <header class="section-header section-header-tweets" style="margin-bottom: 1.6rem !important;">
      <h4 class="text-transparencia ">
        ÚLTIMOS TWEETS
      </h4>
      <hr style="border-top-color: rgba(0, 0, 0, 0.15) !important;" class="mb-0" />
    </header>
    <div class="card" style="border-radius:13px">
      <div class="card-body">
        <div class="row custom-scrollbar" style="overflow-y: scroll; max-height: 462px">
          <div class="col">
            <a class="twitter-timeline" href="https://twitter.com/ACTransparencia?ref_src=twsrc%5Etfw" data-tweet-limit="5" data-chrome="noheader nofooter"></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>

</style>
<script>
export default {
  name: "view-tweets",
  // metaInfo: {
  //   title: "tweets",
  // },
  components: {},
  data: () => ({
    tweets: [],
  }),
  methods: {
    fetchTweets: function() {
      let externalScript = document.createElement("script");
      externalScript.setAttribute("src", "https://platform.twitter.com/widgets.js");
      document.head.appendChild(externalScript);
    },
  },
  mounted: function() {
    this.fetchTweets();
  },
};
</script>
